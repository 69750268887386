// Swiper JS
import Swiper, {Navigation, Autoplay, Pagination, EffectCoverflow} from "swiper"

function initSwiper(event) {
    const backendSettings = {
        autoplay: false,
        grabCursor: false,
        draggable: false,
        allowTouchMove: false,
    }

    new Swiper('.swiper-images', {
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 1,
        spaceBetween: 16,
        grabCursor: true,
        loop: true,
        observer: true,
        observeSlideChildren: true,
        resizeObserver: true,
        autoplay: {
            delay: 2500,
        },
        pagination: {
            el: '.pagination-images',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
    });
    new Swiper('.swiper-images-backend', {
        ...backendSettings,
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        resizeObserver: true,
        observer: true,
        observeSlideChildren: true,
        pagination: {
            el: '.pagination-images',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
    });

    new Swiper('.swiper-carousel', {
        modules: [Navigation, Autoplay, Pagination, EffectCoverflow],
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        loop: true,
        slidesPerView: 2,
        spaceBetween: 16,
        coverflowEffect: {
            rotate: 40,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
        },
        pagination: {
            el: ".swiper-pagination",
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
        }
    });
    new Swiper('.swiper-carousel-backend', {
        ...backendSettings,
        modules: [Navigation, Autoplay, Pagination, EffectCoverflow],
        effect: "coverflow",
        centeredSlides: true,
        loop: true,
        slidesPerView: 2,
        spaceBetween: 16,
        coverflowEffect: {
            rotate: 40,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
        },
        pagination: {
            el: ".swiper-pagination",
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
        },
    });

    new Swiper('.swiper-cards-h', {
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 'auto',
        spaceBetween: 32,
        grabCursor: true,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },

        breakpoints: {
            992: {
                slidesPerView: 2,
            },
        }
    });
    new Swiper('.swiper-cards-h-backend', {
        ...backendSettings,
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 'auto',
        spaceBetween: 32,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },

        breakpoints: {
            992: {
                slidesPerView: 2,
            },
        }
    });

    new Swiper('.swiper-cards-v', {
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 'auto',
        spaceBetween: 32,
        grabCursor: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev-cards-v',
            nextEl: '.swiper-button-next-cards-v',
        },

        breakpoints: {
            992: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        }
    });
    new Swiper('.swiper-cards-v-backend', {
        ...backendSettings,
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 'auto',
        spaceBetween: 32,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev-cards-v',
            nextEl: '.swiper-button-next-cards-v',
        },

        breakpoints: {
            992: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        }
    });
}

window.addEventListener("load", initSwiper)
